<template>
  <div class="vision">
    <BNavbar></BNavbar>
    <div class="py-5 d-flex bg-vision align-items-center">
      <b-container>
        <b-row class="py-5">
          <b-col sm="12" md="10" class="text-center mx-auto">
            <div v-if="visible">
              <h1 class="text-primary font-weight-light display-4">
                <typewriter class="typewriter" :type-interval="30">
                  Simplificando el día a día a través de ecosistemas digitales
                </typewriter>
              </h1>
            </div>
          </b-col>
          <!-- <b-col sm="12" md="8" class="mx-auto mt-5">
                        <iframe width="100%" height="400" src="https://www.youtube.com/embed/Rl_FyZDri_Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </b-col> -->
        </b-row>
      </b-container>
    </div>

    <b-container>
      <b-row class="mb-5 py-5">
        <b-col cols="6" offset-lg="1" lg="2" class="text-center mt-5">
          <b-row class="d-flex justify-content-center">
            <h1 class="font-weight-light">+</h1>
            <number
              ref="number1"
              :from="0"
              :to="30000"
              :format="theFormat"
              :duration="4"
              :delay="3.5"
              class="h1 font-weight-light"
            />
          </b-row>
          <b-row class="d-flex justify-content-center">
            <p class="font-weight-regular small text-uppercase ls-sable">
              clientes
            </p>
          </b-row>
        </b-col>
        <b-col cols="6" lg="2" class="text-center mt-5">
          <b-row class="d-flex justify-content-center">
            <h1 class="font-weight-light">+</h1>
            <number
              ref="number2"
              :from="0"
              :to="50"
              :format="theFormat"
              :duration="4"
              :delay="3.5"
              class="h1 font-weight-light"
            />
          </b-row>
          <b-row class="d-flex justify-content-center">
            <p class="font-weight-regular small text-uppercase ls-sable">
              productos
            </p>
          </b-row>
        </b-col>
        <b-col cols="6" lg="2" class="text-center mt-5">
          <b-row class="d-flex justify-content-center">
            <h1 class="font-weight-light">+</h1>
            <number
              ref="number3"
              :from="0"
              :to="600"
              :format="theFormat"
              :duration="4"
              :delay="3.5"
              class="h1 font-weight-light"
            />
          </b-row>
          <b-row class="d-flex justify-content-center">
            <p class="font-weight-regular small text-uppercase ls-sable">
              colaboradores
            </p>
          </b-row>
        </b-col>
        <b-col cols="6" lg="2" class="text-center mt-5">
          <b-row class="d-flex justify-content-center">
            <h1 class="font-weight-light">+</h1>
            <number
              ref="number4"
              :from="0"
              :to="200000"
              :format="theFormat"
              :duration="4"
              :delay="3.5"
              class="h1 font-weight-light"
            />
          </b-row>
          <b-row class="d-flex justify-content-center">
            <p class="font-weight-regular small text-uppercase ls-sable">
              usuarios
            </p>
          </b-row>
        </b-col>
        <b-col cols="12" lg="2" class="text-center mt-5">
          <b-row class="d-flex justify-content-center">
            <h1 class="font-weight-light"></h1>
            <number
              ref="number5"
              :from="0"
              :to="5"
              :format="theFormat"
              :duration="5"
              :delay="3.5"
              class="h1 font-weight-light"
            />
          </b-row>
          <b-row class="d-flex justify-content-center">
            <p class="font-weight-regular small text-uppercase ls-sable">
              países
            </p>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="7" class="mt-5">
          <div class="h1 text-primary font-weight-light mb-5">
            Quiénes somos
          </div>

          <p class="font-weight-regular">
            Somos un ecosistema de soluciones digitales en torno al propósito
            común de simplificar el día a día y ahorrar tiempo a todas las
            empresas y personas que interactúan con nosotros.
          </p>

          <p class="font-weight-regular">
            Nuestras distintas empresas y sus productos están interconectados,
            de forma de brindar a nuestros clientes una experiencia unificada de
            servicio, tanto en su accesibilidad como en el manejo integrado de
            su data.
          </p>

          <p class="font-weight-regular">
            Estamos siempre en búsqueda de nuevas piezas que complementen
            nuestro ecosistema, sea por creación, adquisición y/o una mayor
            internacionalización de las que ya tenemos.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <div class="bg-vision-buss py-5">
      <b-container class="bg-footer br">
        <b-row class="py-5">
          <b-col sm="12">
            <div class="h1 text-primary font-weight-light mb-3">
              Nuestros negocios
            </div>
          </b-col>
          <b-col sm="12" md="7">
            <p class="font-weight-regular">
              Nuestro portafolio de soluciones digitales es muy amplio, y se
              agrupa en tres verticales distintas de negocios: HCM, ERP y
              Habilitación Digital.
            </p>

            <p class="font-weight-regular">
              Para efectivamente acelerar tu digitalización, buscamos apoyarte
              en tres ámbitos conceptuales fundamentales:
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="4">
            <div class="p font-weight-bold small text-uppercase ls-sable">
              Tecnología Core
            </div>
            <div class="p text-muted font-weight-regular p-0 mt-2">
              Optimizamos tus procedimientos internos y la gestión de tus
              personas.
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col sm="12" md="4">
            <div class="p font-weight-bold small text-uppercase ls-sable">
              Tecnología Habilitadora
            </div>
            <div class="p text-muted font-weight-regular p-0 mt-2">
              te conectamos con tus distintos stakeholders eficientemente
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col sm="12" md="4">
            <div class="p font-weight-bold small text-uppercase ls-sable">
              Inteligencia de Negocios
            </div>
            <div class="p text-muted font-weight-regular p-0 mt-2">
              te ayudamos a procesar tu data y la del mercado para apoyar tus
              decisiones.
            </div>
            <router-link
              v-on:click="scrollToTop"
              to="/ecosistema"
              class="btn btn-primary mt-5"
              >Ir a Ecosistema</router-link
            >
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="py-5">
      <b-row>
        <b-col sm="12">
          <div class="h1 text-primary font-weight-light mb-3">Equipo Sable</div>
        </b-col>
        <b-col sm="12" md="7">
          <p class="font-weight-regular">
            En nuestros +600 colaboradores buscamos el equilibrio entre
            experiencia y juventud. Conoce a nuestros principales exponentes:
          </p>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <!-- Hector Gomez -->
        <b-col class="mb-5" cols="6" offset-md="1" md="3">
          <div class="card-team">
            <img
              src="/assets/hectorgomez.webp"
              width="255"
              height="395"
              alt="Héctor Gómez"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/company/sable-latam"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Héctor Gómez</a
            >
            <div class="small text-uppercase ls-sable">presidente</div>
          </div>
        </b-col>

        <!-- Rodrigo Castro -->
        <b-col class="mb-5" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/rodrigocastro.webp"
              width="255"
              height="395"
              alt="Rodrigo Castro"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/company/sable-latam"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Rodrigo Castro</a
            >
            <div class="small text-uppercase ls-sable">director</div>
          </div>
        </b-col>

        <!-- Diego Gomez -->
        <b-col class="mb-5" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/diegogomez.webp"
              width="255"
              height="395"
              alt="Diego Gómez"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/in/diego-g%C3%B3mez-3277a049"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Diego Gómez</a
            >
            <div class="small text-uppercase ls-sable">Director Ecosistema INNOVA</div>
          </div>
        </b-col>

        <!-- Andrés Gómez -->
        <b-col class="mb-5" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/andresgomez.webp"
              width="255"
              height="395"
              alt="Andrés Gómez"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/in/andr%C3%A9s-ignacio-g%C3%B3mez-l%C3%B3pez-139a1236"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Andrés Gómez</a
            >
            <div class="small text-uppercase ls-sable">director ecosistema hcm</div>
          </div>
        </b-col>

        <!-- Nicolás López -->
        <b-col class="mb-5" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/nicolaslopez.webp"
              width="255"
              height="395"
              alt="Nicolás López"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/in/nicolas-lopez-torres"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Nicolás López</a
            >
            <div class="small text-uppercase ls-sable">cfo</div>
          </div>
        </b-col>

        <!-- María Victoria Puentes -->
        <b-col class="mb-5" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/mariavictoriapuentes.webp"
              width="255"
              height="395"
              alt="María Victoria Puentes"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/in/mar%C3%ADa-victoria-puentes-cosmelli-524a7823"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >María Victoria Puentes</a
            >
            <div class="small text-uppercase ls-sable">
              ceo manager software
            </div>
          </div>
        </b-col>

        <!-- Federico Álvarez -->
        <b-col class="mb-5" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/federicoalvarez.webp"
              width="255"
              height="395"
              alt="Federico Álvarez"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/in/federico-alvarez-danker-8a499324"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Federico Álvarez</a
            >
            <div class="small text-uppercase ls-sable">ceo random</div>
          </div>
        </b-col>

        <!-- Alejandro Martínez -->
        <b-col class="mb-5" offset-md="1" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/alejandromartinez.webp"
              width="255"
              height="395"
              alt="Alejandro Martínez"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/in/alejandro-mart%C3%ADnez-verdugo"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Alejandro Martínez</a
            >
            <div class="small text-uppercase ls-sable">ceo jumpitt labs</div>
          </div>
        </b-col>

        <!-- Juan Jose Izcue -->
        <b-col class="mb-5" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/juanjoseizcue.webp"
              width="255"
              height="395"
              alt="Juan Jose Izcue"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/in/juan-izcue-5577a276"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Juan José Izcúe</a
            >
            <div class="small text-uppercase ls-sable">ceo senegocia</div>
          </div>
        </b-col>

        <!-- Rodolfo Cuevas -->
        <b-col class="mb-5" cols="6" md="3">
          <div class="card-team">
            <img
              src="/assets/rodolfocuevas.webp"
              width="255"
              height="395"
              alt="Rodolfo Cuevas"
            />
          </div>
          <div class="mt-3">
            <a
              href="https://www.linkedin.com/in/rodolfo-cuevas-url"
              target="_blank"
              class="h5 font-weight-bold text-primary mb-1"
              >Rodolfo Cuevas</a
            >
            <div class="small text-uppercase ls-sable">ceo icar</div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div class="bg-geography mt-5">
      <b-container>
        <b-row>
          <b-col sm="12" md="6">
            <div class="h1 text-primary font-weight-light mb-3">
              Presencia Geográfica
            </div>
            <p class="font-weight-regular">
              En nuestros +600 colaboradores buscamos el equilibrio entre
              experiencia y juventud. Conoce a nuestros principales exponentes:
            </p>
            <h6 class="mt-5 pt-5">CHILE</h6>
            <p class="font-weight-regular">
              Av. Apoquindo Nº 4499, piso 6, 10 y 15, Las Condes, Santiago.
            </p>
            <p class="font-weight-regular">
              Errázuriz Nº 755, oficina 602, 603, 604, 605, Valparaíso, Chile.
            </p>
            <h6 class="mt-3 pt-2">COLOMBIA</h6>
            <p class="font-weight-regular">
              Carrera 11a #93-52, Oficina 304, Edificio Torre 93, Bogotá.
            </p>
            <h6 class="mt-3 pt-2">PERÜ</h6>
            <p class="font-weight-regular">
              Calle Amador Merino Reyna 465 Of. 601–A. San Isidro. Lima.
            </p>
            <h6 class="mt-3 pt-2">MËXICO</h6>
            <p class="font-weight-regular">
              Darwin 74 Piso 2, Of. 2A • Colonia Nva. Anzures Ciudad de México •
              C.P.115.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="py-5">
      <b-row>
        <b-col sm="12">
          <div class="h1 text-primary font-weight-light mb-3">
            Historia de Sable
          </div>
        </b-col>
        <b-col sm="12" md="7">
          <p class="font-weight-regular">
            Más de 40 años de innovación constante e intensa actividad en
            funciones y adquisiciones
          </p>
        </b-col>

        <b-col cols="12" md="10" lg="7">
          <vue-timeline-update
            class="pt-5 px-0"
            :date="new Date('1980')"
            description="Héctor Gómez inicia el proyecto Sable al fundar, junto a otros accionistas, la empresa ORDEN. Su misión fue generar las primeras plataformas informáticas para grandes instituciones en Latinoamérica"
            icon=""
            title=""
            category="1980"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('1987')"
            description="Se incorpora <strong>DICOM</strong>"
            icon=""
            title=""
            category="1987"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('1995')"
            description="Se incorpora <strong>PAYROLLL</strong>"
            icon=""
            title=""
            category="1995"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('1997')"
            description="Se desprende <strong>DICOM</strong> adquirida en su totalidad por <strong>EQUIFAX</strong>"
            icon=""
            title=""
            category="1997"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2000')"
            description="Se crea <strong>TECHONE GROUP</strong>"
            icon=""
            title=""
            category="2000"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2002')"
            description="Se incorpora <strong>INMOTION</strong> </br> Se crea <strong>IMED</strong> "
            icon=""
            title=""
            category="2002"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2004')"
            description="Se desprende <strong>ORDEN</strong>, adquirida en su totalidad por <strong>SONDA</strong>"
            icon=""
            title=""
            category="2004"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2006')"
            description="Se crea <strong>AUTENTIA</strong>"
            icon=""
            title=""
            category="2006"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2010')"
            description="Se incorpora <strong>VISUAL TIME</strong> </br> Se incorpora <strong>MANAGER</strong>"
            icon=""
            title=""
            category="2010"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2011')"
            description="Se incorpora <strong>MANDOMEDIO</strong> </br> Se crea <strong>VIXONIC</strong>"
            icon=""
            title=""
            category="2011"
            color="black"
          />

          <vue-timeline-update
            :date="new Date('2012')"
            description="Se incorpora <strong>ACEPTA</strong> </br> Se incorpora <strong>RANDOM</strong>"
            icon=""
            title=""
            category="2012"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2013')"
            description="Se desprende <strong>PAYROLL</strong>, adquirida en su totalidad por <strong>ADP</strong>"
            icon=""
            title=""
            category="2013"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2014')"
            description="Se incorpora <strong>SENEGOCIA</strong> </br> Se crea <strong>INSIGNI</strong>"
            icon=""
            title=""
            category="2014"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2016')"
            description="Se incorpora <strong>INTERCAP/BNOVUS</strong> </br> Se crea <strong>ICAR</strong>"
            icon=""
            title=""
            category="2016"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2017')"
            description="Se incorpora <strong>JUMPITT</strong> </br>  Se crea <strong>REX+</strong> </br>  Se desprende <strong>IMED</strong> adquirida por <strong>ACELL-KKR</strong>"
            icon=""
            title=""
            category="2017"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2018')"
            description="Se incorpora <strong>GAXU</strong> </br> Se incorpora <strong>FLEXLINE ERP</strong> </br> Se crea <strong>NOTARISA</strong> </br> Se crea <strong>TRUST</strong>"
            icon=""
            title=""
            category="2018"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2019')"
            description="Se crea <strong>MANAGER+</strong> </br> Se crea <strong>FINGO</strong></br> Se consolidan todas las empresas <strong>SABLE</strong> bajo un mismo accionariado, exceptuando <strong>INMOTION</strong>"
            icon=""
            title=""
            category="2019"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2020')"
            description="Se desprende <strong>ACEPTA</strong> adquirida por <strong>SOVOS</strong>"
            icon=""
            title=""
            category="2020"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2021')"
            description="Se invierte en <strong>CINCEL</strong> y <strong>BEWELL</strong> </br> Se invierte en <strong>HAPP</strong> </br> Se invierte en <strong>Riivi</strong>"
            icon=""
            title=""
            category="2021"
            color="black"
          />
          <vue-timeline-update
            :date="new Date('2022')"
            description="Se invierte en <strong>Transa tu auto</strong> y <strong>Regcheq</strong>"
            icon=""
            title=""
            category="2022"
            color="black"
            is-last
          />
        </b-col>
      </b-row>
    </b-container>

    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import BNavbar from "@/components/BNavbar.vue";
import Footer from "@/components/Footer.vue";
import Typewriter from "typewriter-vue";

export default {
  name: "Home",
  components: {
    BNavbar,
    Footer,
    Typewriter,
  },
  data() {
    return {
      scrollPosition: null,
      visible: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    setTimeout(() => (this.visible = true), 1000);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    theFormat(number) {
      return number.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    completed() {
      console.log("Animation ends!");
    },
    playAnimation() {
      this.$refs.number2.play();
    },
  },
  metaInfo() {
    return {
      title: "Quienes somos | Grupo Sable",
      meta: [
        {
          name: "description",
          content:
            "Somos un ecosistema de soluciones digitales en torno al propósito común de simplificar el día a día de las empresa y las personas.",
        },
      ],
      link: [{ rel: "canonical", href: "https://sable.lat/quienessomos" }],
    };
  },
};
</script>

<style>
[class^="gb-"]:not(.gb-base-icon) {
  font-family: "work-sans", Helvetica, sans-serif !important;
  font-size: 0.8rem;
}
</style>
